<template>
  <b-container fluid  class="mt-2" v-if="options">
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <b-row class="mt-2">
      <b-col class="d-flex justify-content-start">
        <h4>{{ this.title }}</h4>
      </b-col>
    </b-row>
    <!-- CABECERA PUNTO DE VENTA -->
    <b-row>
      <b-col cols="md-12">
        <receipt-header
        @headerchanged="headerUpdated"
        @clientchanged="clientUpdated"
        :show-pricelist="options.showPricelist"
        :show-prices="options.showPrices"
        :show-choosedate="options.showChoosedate"
        :show-pos="options.showPos"
        :posnumbers="options.posnumbers"
        :showservicedate="showservicedate"
        :config="options.config"
        />
      </b-col>
    </b-row>
    <!-- TABLA DE ADD PRODUCTOS -->
    <b-row>
      <b-col cols="md-8">
        <receipt-products v-if="payload.header.pricelist_id"
        :show-prices="options.showPrices"
        :show-barcodes="options.showBarcodes"
        :keep-items="options.keepItemsState"
        @productschanged="productsUpdated"
        @sendProduct="sendProduct"
        :config="options.config"
        :disciva="disciva"
        :allow_sale_without_stock="options.allow_sale_without_stock"
        :allow_edit_price="options.allow_price_edit"
        :discount_each_line="options.discount_each_line"
        :discount_each_line_type="options.discount_each_line_type"
        :prexistprd="options.prexistprd"
        :priceList="payload.header.pricelist_id"
        :productsAux='products'
        />
      </b-col>
      <!-- CUADRO TOTALES -->
      <b-col cols="md-4" class="d-flex flex-column min-vh-auto">
        <b-row class="mt-2 mb-1" v-if="this.$route.name != 'Nuevo presupuesto' && this.$route.name != 'Nuevo remito de venta'">
          <b-col cols="6">
            <b-button
              @click="showDraftModal = true" 
              type="button"
              variant="outline-primary"
              title="Seleccionar borrador" 
              class="btn btn-block">
              Cargar <b-icon-box-arrow-in-up></b-icon-box-arrow-in-up>
            </b-button>
          </b-col>
        <b-col cols="6">
            <b-button v-if="hasProducts && totalUpperToSixDigit && hasClient" 
              @click="saveDraft" 
              type="button"
              variant="primary"
              title="Guardar venta" 
              class="btn btn-block">
              Guardar <b-icon-box-arrow-in-down></b-icon-box-arrow-in-down>
            </b-button>
            <b-button  disabled v-else 
              @click="saveDraft" 
              type="button"
              variant="primary"
              title="Guardar venta"
              class="btn btn-block">
              Guardar <b-icon-box-arrow-in-down></b-icon-box-arrow-in-down>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="my-0">
          <b-col>
            <receipt-totals v-if="options.showPrices"
            v-bind:products="products"
            v-on:totalschanged="totalsUpdated"
            :disciva="disciva"
            :discount_each_line="options.discount_each_line"
            :discount_each_line_type="options.discount_each_line_type"
            :discount="payload.discount"
            :clientDiscount="clientDiscount"
            :grossTotal="payload.grossTotal"
            :priceTotal="payload.priceTotal"/>
          </b-col>
        </b-row>
        <b-row class="my-0">
          <b-col>
              <h6>
                <label for="">Nota para el cliente</label>
              </h6>
              <textarea v-model="payload.saleNote" class="form-control" rows="2"></textarea>
          </b-col>
        </b-row>
        <b-row v-if="this.title === 'Nueva Venta'" class="mb-3 mt-2">
          <b-col cols="6">
            <b-button v-if="hasProducts && totalUpperToSixDigit && hasClient"
              @click="cashMethod" 
              type="button" 
              variant="success" 
              size="md" 
              class="p-3 btn btn-block">
              Efectivo<b-icon-coin class="ml-3"></b-icon-coin>
            </b-button>
            <b-button disabled v-else  
              @click="cashMethod" 
              type="button" 
              variant="success" 
              size="md" 
              class="p-3 btn btn-block">
              Efectivo<b-icon-coin class="ml-3"></b-icon-coin>
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button v-if="hasProducts && totalUpperToSixDigit && hasClient && clientType"
              @click="accountsMethod" 
              type="button" 
              variant="info" 
              size="md" 
              class="p-3 btn btn-block">
              Cuenta Cte.<b-icon-card-heading class="ml-3"></b-icon-card-heading>
            </b-button>
            <b-button disabled v-else
              @click="accountsMethod" 
              type="button" 
              variant="info" 
              size="md" 
              class="p-3 btn btn-block">
              Cuenta Cte.<b-icon-card-heading class="ml-3"></b-icon-card-heading>
            </b-button>
          </b-col>
          <b-col cols="12 mt-3">
            <b-button v-if="hasProducts && totalUpperToSixDigit && hasClient"
              @click="receiptComplete" 
              type="button" 
              variant="orange" 
              size="md" 
              class="p-3 btn btn-block">
              Editar<b-icon-pencil-square class="ml-3"></b-icon-pencil-square>
            </b-button>    
            <b-button disabled v-else
              @click="receiptComplete" 
              type="button" 
              variant="orange" 
              size="md" 
              class="p-3 btn btn-block">
              Editar<b-icon-pencil-square class="ml-3"></b-icon-pencil-square>
            </b-button>
          </b-col>
          
        </b-row>
        <b-row v-else>
          <b-col cols="12 mt-3">
            <b-button v-if="hasProducts && totalUpperToSixDigit && hasClient"
              @click="receiptComplete" 
              type="button" 
              variant="orange" 
              size="md" 
              class="p-3 btn btn-block">
              Finalizar
            </b-button>    
            <b-button disabled v-else
              @click="receiptComplete" 
              type="button" 
              variant="orange" 
              size="md" 
              class="p-3 btn btn-block">
              Finalizar
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <!-- MODAL METODOS DE PAGO -->
      <b-container>
        <receipt-payment-methods
        v-if="showModal"
        :header="payload.header"
        :fundDefault="options.config.fund_default"
        @paymentmethodschanged="paymentMethodsUpdated"
        @paymentmethodsclose="paymentMethodsClose"
        :payment-methods="options.payment_methods"
        :total="payload.total"
        :limited="true"
        :cc-only="payload.cc_only"
        :gen-type="options.genType"
        @close="showModal = false"/>
      </b-container>


      <!-- MODAL BORRADORES -->
      <b-container>
        <b-modal size="lg" v-model="showDraftModal" title="Borradores de factura" @show="getDrafts">
          <b-table-simple small caption-top responsive>
            <b-thead>
              <b-tr>
                <b-th></b-th>
                <b-th class="text-center">Fecha</b-th>
                <b-th>Nro Identificador</b-th>
                <b-th class="text-center">Cliente</b-th>
                <b-th>Pto Venta</b-th>
                <b-th>Total</b-th>
                <b-th>Vendedor</b-th>
                <b-th>Acciones</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(saleDraft, index) in salesDraft" :key="index">
                <b-td><b-checkbox v-model="selectedDrafts" :value="saleDraft.id"></b-checkbox></b-td>
                <b-td>{{ formatoFechaHora(saleDraft.created_at) }}</b-td>
                <b-td class="text-center">{{ saleDraft.id }}</b-td>
                <b-td class="text-center">{{ saleDraft.client_name }}</b-td>
                <b-td class="text-center">{{ saleDraft.pos }}</b-td>
                <b-td class="text-canter">{{ saleDraft.total }}</b-td>
                <b-td class="text-center" v-if="saleDraft.salesclerk !== null">{{ saleDraft.salesclerk }}</b-td>
                <b-td v-else></b-td>
                <b-td>
                  <span style="cursor: pointer;" @click="deleteDraft(saleDraft.id)" aria-hidden="true" title="Borrar producto">
                    <button class="btn btn-outline-danger"><i class="bi bi-dash-circle"></i></button>
                  </span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <template #modal-footer="{ ok, cancel, hide }">
            <b-button class="mr-4" size="lg" variant="outline-primary" @click="cancel()">
              Cancelar
            </b-button>
            <b-button size="lg" variant="primary" @click="getDraft">
              Cargar
            </b-button>
          </template>
        </b-modal>
      </b-container>
    </b-row>
  </b-container>
</template>


<script>
import receiptProducts from './Receipt-products.vue';
import receiptHeader from './Receipt-header.vue';
import receiptTotals from './Receipt-totals.vue';
import receiptPaymentMethods from '../paymentmethods/Receipt-payment-methods.vue';
import { mapGetters, mapActions } from 'vuex'
import formatDateMixin from "../../../../mixins/formatDateMixin";

  export default {
    props: ['configurl'],
    mixins: [formatDateMixin],
    data: function() {
      return {
        options: null,
        modalProduct: false,
        showModal: false,
        showDraftModal: false,
        disciva: false,
        products: [],
        salesDraft: [],
        saleDraft:[],
        selectedDrafts: null,
        showservicedate: false,
        clientDiscount:null,
        ccLimit:null,
        config: null,
        payload: {
          header: JSON.parse(localStorage.getItem('header')) || {},
          items: JSON.parse(localStorage.getItem('products')) || [],
          discount: 0,
          p_discount:0,
          total: 0,
          paymentMethods: {},
          saleNote: '',
          fromReceipt: '',
          cc_only:0,
          grossTotal: 0,
          priceTotal: 0,
          draft: false
        },
        loading: false,
        title: '',
      }
    },
    mounted () {
      this.getConfiguration(this.configurl);
      this.getTitle();
    },
    watch: {
      showConfiguration (newshowConfig) {
        if (typeof newshowConfig.config !== 'undefined') {
          this.options = newshowConfig;
          this.payload.saleNote = newshowConfig.note_default;
          this.payload.fromReceipt = newshowConfig.config.fromReceipt;
        }
      }
    },
    computed: {
      ...mapGetters({
        showConfiguration: 'config/showConfiguration',
        authUser: 'authUser/getUser'
      }),
      isSale(){
        if(this.configurl === '/api/sales/create'){
          return true;
        }else {
          return false;
        }
      },
      hasProducts(){
        return (this.payload.items.length>0);
      },
      totalUpperToSixDigit() {
        if (this.payload.total >= 9999999999999.99) {
          alert('Se pas&oacute; el l&iacute;mite de $9.999.999.999.999,99, por favor ingrese menos productos.');
          return false;
        } else {
          return true;
        }
      },
      hasClient(){
        if(this.payload.header.client_id==null){
          return false;
        }else{
          return true;
        }
      },
      clientType(){
        if(this.payload.header.client_id == 1){
          return false;
        }else{
          return true;
        }
      },
      isOnlyCash(){
        return (this.options.payment_methods.cheques==0 && this.options.payment_methods.cuenta_corriente==0 && this.options.payment_methods.tarjetas==0);
      },
      isOnlyCashNC(){
        return (this.options.genType=='NC' && this.options.payment_methods.cuenta_corriente==0);
      }
    },
    methods: {
      ...mapActions({
        getConfiguration: 'config/getConfiguration'
      }),
      round: function(value, decimals) {
        return (Number(Math.round(value + 'e' + decimals) + 'e-' + decimals));
      },
      calcprod : function() {
        var app = this;
        if (app.debug) {console.log('calculando productos');}
        let discountTotal=0;
        let grossTotal=0;
        let priceTotal=0;
        for (var i = 0; i < app.products.length; i++) {
          if ( (app.products[i].promorules.length > 0) && (typeof app.products[i].quantity == 'number') ) {
            var findi2 = -1;
            for (var i2 = 0; i2 < app.products[i].promorules.length; i2++) {
              if ((app.products[i].quantity >= app.products[i].promorules[i2].fromqty) && (app.products[i].quantity<app.products[i].promorules[i2].toqty)) {
                findi2 = i2;
              }
            }
            if (findi2>-1) app.products[i].price = app.products[i].promorules[findi2].price;
              else app.products[i].price=app.products[i].bkprice;
          }

          if(app.options.keepItemsState==true){

           if (app.products[i].includetax==1) {
              app.products[i].gross = app.products[i].price / (1+(app.products[i].taxtype/100));
            } else {
              app.products[i].gross = app.products[i].price;
            }
            app.products[i].tax = app.products[i].gross * app.products[i].taxtype/100;

          }else{

            if (app.products[i].includetax==1) {
              app.products[i].gross = app.products[i].price / (1+(app.products[i].taxtype/100));
            } else {
              app.products[i].gross = app.products[i].price;
            }
            app.products[i].tax = app.products[i].gross * app.products[i].taxtype/100;
            
          }

          if(app.options.discount_each_line==1){
            if(app.options.discount_each_line_type !== undefined && app.options.discount_each_line_type == 'amount'){
              discountTotal = discountTotal + app.products[i].discount * app.products[i].quantity;
            } else {
              if(app.options.disciva !== undefined && app.options.disciva == true)
                discountTotal = discountTotal + ((app.products[i].discount * app.products[i].grossprice)/ 100) * app.products[i].quantity;
              else
                discountTotal = discountTotal + ((app.products[i].discount * app.products[i].price)/ 100) * app.products[i].quantity;
            }
          }

          grossTotal = grossTotal + app.products[i].gross * app.products[i].quantity;
          priceTotal = priceTotal + app.products[i].price * app.products[i].quantity;
        }

        app.payload.grossTotal= grossTotal;
        app.payload.priceTotal= priceTotal;

        if(app.options.discount_each_line==1)
          app.payload.discount=discountTotal;
      },
      receiptComplete: function() {
        if(this.payload.total == 0 && this.showPrices)
          alert('No puede facturar por 0 pesos.');
        else {
          if (this.isOnlyCash || !this.options.showPaymentmethods || this.isOnlyCashNC){
            this.payload.paymentMethods={
              cash: this.payload.total,
              fund_id: this.options.config.fund_default,
              accounts: [],
              creditcards: [],
              tpchecks: []
            };
            this.sendReceipt();
          }else{
            this.showModal=true;
          }
        }
      },
      sendReceipt: function() {
        var app=this;
        app.loading = true;
        for (var i = 0; i < app.payload.items.length; i++) {
          delete app.payload.items[i].code;
          delete app.payload.items[i].barcode;
          delete app.payload.items[i].stock;
          delete app.payload.items[i].promorules;
        }
        if(this.title == 'Nueva Venta'){
          const {saleId} = app.$route.params
          axios.post('/api/sales/destroyDraft/' + saleId)
        }

        if (app.debug) {console.log('payload'+' '+app.payload);}
        window.axios.post(app.options.endpointurl, app.payload)
        .then(function (response) {
          if (response.data.success) {
            localStorage.removeItem('header');
            localStorage.removeItem('products');
            if (response.data.data.printInvoice) {
              if (app.$route.name === "Nueva venta") {
                if (response.data.data.printTicket) {
                  if (app.authUser.company_id==182) {
                    window.location.href = '/sales/ticketg/'+response.data.data.ID;
                  } else {
                    window.location.href = '/sales/ticket/'+response.data.data.ID;
                  }
                } else {
                  window.open(response.data.data.urlInvoice, '_blank');
                  window.location.href = '/ventas/index/';
                }
              } else {
                if (response.data.data.printOrdernoteTicket) {
                  window.location.href = '/ordernotes/ticket/'+response.data.data.ID;
                } else {
                  window.open(response.data.data.urlInvoice, '_blank');
                  window.location.href = '/ventas/index/';
                }
              }
              
            } else {
              if(response.data.data.sendEmail) {
                if (response.data.data.clientEmail) {
                  alert("El email se envio correctamente");
                } else {
                  alert("El Cliente no tiene un email asignado");
                }
              }
              alert(response.data.data.responseText);
              if (!app.debug) {window.location.href = window.location.pathname;}
            }
          } else {
            alert(response.data.message);
          }
          app.loading = false;
        })
        .catch(function (error) {
          alert(error.response.data.message);
          app.loading = false;
          window.location.href = window.location.pathname;
        });
      },
      headerUpdated: function(obj) {
        var app = this;
        app.payload.header = obj;
        if (app.payload.header.letter=="A" || app.payload.header.letter=="M") {
          app.disciva=true;
        } else {
          app.disciva=false;
        }
        app.calcprod();
        localStorage.setItem('header', JSON.stringify(obj));
      },
      clientUpdated:function(id){
        var app = this;
        if (id!=null) {
          window.axios.get('/api/clients/getClient/'+id)
          .then(function (response) {
            var client = response.data.data;
            if (client.discount>"0") {
              app.clientDiscount = client.discount;
            }
            app.ccLimit = client.ccLimit;
            app.payload.cc_only = client.cc_only;
          }).catch(function (error) {
            alert(error.message);
          });
        }
      },
      clearproducts: function () {
        this.products = this.products.filter(el => (el.id !== 0 || el.type == 'LABEL'));
        this.calcprod();
      },
      paymentMethodsUpdated: function(obj) {
        var vm = this;
        vm.payload.paymentMethods = obj;
        if (Object.keys(obj).length === 0 && obj.constructor === Object) {
          vm.clearproducts();
        } else {
          if (vm.payload.paymentMethods.creditcards.length > 0) {
            console.log('tarjeta detectada!');
            var interests = vm.payload.paymentMethods.creditcards.map( e => e.interest).reduce((acc, currentValue) => acc + currentValue, 0);
            if (interests > 0) {
            var newitem = { 
                            quantity: 1, 
                            id: 0,
                            price: interests, 
                            bkprice: interests, 
                            name: "Intereses por tarjeta", 
                            includetax: 1,
                            taxtype: "21",
                            type: "CARD",
                            unit: "U",
                            gross: interests / (1+(21/100)),
                            tax: (interests / (1+(21/100))) * 21/100,
                            barcode: null,
                            code: null,
                            promorules: [],
                            stock:0,
                          };
              vm.products.push(newitem);
              vm.calcprod();
            }
          }
        }
      },
      paymentMethodsClose: function(obj) {
        var pass = true;
        var app = this;
        app.payload.paymentMethods = obj;
        if ((app.payload.paymentMethods.accounts.length>0) && (app.ccLimit!=null) &&(app.ccLimit<app.payload.paymentMethods.accounts[0].amount)){
          if (confirm("La cuenta corriente excederá su límite ¿Realizar la venta de todas maneras?") == false){
            pass = false;
          }
        }

        if (pass == true){
          this.paymentMethodsUpdated(obj);
          this.productsUpdated(this.products);
          this.sendReceipt();
        }
      },
      productsUpdated: function(obj) {
        this.payload.items = obj;
        this.products = obj;
        this.calcprod();
        this.showservicedate=false;
        localStorage.setItem('products', JSON.stringify(this.products));
        for (var i = 0; i < obj.length; i++) {
          if (obj[i].type=='SERVICE') { this.showservicedate=true; return; }
        }
      },
      totalsUpdated: function(obj) {
        this.payload.discount = obj.tdiscount;
        this.payload.p_discount = obj.pdiscount;
        this.payload.subtotal = this.round(obj.net,2);
        this.payload.total = this.round(obj.total,2);
      },
      sendProduct(product) {
        this.products.push(product);
        this.calcprod();
        this.productsUpdated(this.products);
      },
      saveDraft(){
        if(this.options.id == undefined){
          this.payload.draft = true;
          this.payload.paymentMethods={
            banks:[],
            cash: this.payload.total,
            fund_id: this.options.config.fund_default,
            fundBanks_id: [],
            accounts: [],
            creditcards: [],
            tpchecks: []
          };
          axios.post('/api/sales/addDraft', this.payload)
          .then((response) => {
          });
          localStorage.removeItem('header');
          localStorage.removeItem('products');
          this.$router.replace('/temporal');
          this.$nextTick(() => {
            this.$router.replace({ 
              name: 'Nueva venta', 
            });
          });
        }else{
          let id = this.options.id;
          axios.post('/api/sales/updateDraft/' + id, this.payload)
          .then((response) => {
          });

        }
      },
      getDrafts(){
        axios.get('/api/sales/getalldrafts')
          .then((response) => {
            this.salesDraft = response.data.data;
          });
      },
      getDraft() {
        var app = this;
        localStorage.removeItem('header');
        localStorage.removeItem('products');
        app.payload.draft = app.selectedDrafts;
        this.$router.replace('/temporal');
        this.$nextTick(() => {
          this.$router.replace({ 
            name: 'Editar venta', 
            params: { saleId: app.selectedDrafts }
          });
        });
      },
      deleteDraft(id){
        if (confirm("¿Está seguro que desea eliminar este borrador?") == true) {
          axios.post('/api/sales/destroyDraft/' + id)
          .then((response) => {            
            if (response.data.code == 200) {
              console.log('Borrador eliminado!');
              this.getDrafts();               
            }
          }).catch((error) => {
            console.error('Error al eliminar el borrador:', error);
          });
        }
      },
      cashMethod() {
        var app = this;
        app.payload.paymentMethods={
          banks:[{ amount: 0 }],
          cash: this.payload.total,
          fund_id: this.options.config.fund_default,
          fundBanks_id: [],
          accounts: [],
          creditcards: [],
          tpchecks: []
        };
        this.sendReceipt();
      },
      accountsMethod() {
        var app = this;
        if(confirm("Desea cerrar esta venta en cuenta corriente?") == true){
          app.payload.paymentMethods={
            banks:[{ amount: 0 }],
            cash: 0,
            fund_id: 0,
            fundBanks_id: [],
            accounts: [{ amount: this.payload.total, days: 30 }],
            creditcards: [],
            tpchecks: []
          };
          if ((app.payload.paymentMethods.accounts.length>0) && (app.ccLimit!=null) &&(app.ccLimit<app.payload.paymentMethods.accounts[0].amount)){
            if (confirm("La cuenta corriente excederá su límite ¿Realizar la venta de todas maneras?") == false){
              pass = false;
            }
          }
          this.sendReceipt();
        }
      },
      getTitle(){
        switch(this.configurl){
          case '/api/sales/create':
            this.title = 'Nueva Venta';
            console.log(this.title);
            break;
          case '/api/creditnotes/create':
            this.title = 'Nueva Nota de Credito';
            break;
          case '/api/debitnotes/create':
            this.title = 'Nueva Nota de Debito';
            break;
          case '/api/budgets/create':
            this.title = 'Nuevo Presupuesto';
            break;
          case '/api/ordernotes/create':
            this.title = 'Nueva Nota de pedido';
            break;
          case '/api/shippingnotes/create':
            this.title = 'Nuevo Remito';
            break;
        }
      }
    },
    components: {
      receiptProducts,
      receiptHeader,
      receiptTotals,
      receiptPaymentMethods
    },
  }
</script>
