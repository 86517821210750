<template>
    <b-container fluid style="margin-bottom: 80px;">
        <div v-if="sale" class="ticket">
            <!-- Logo ticket -->
            <!--    
            <img class="imglogo" 
            src="https://yt3.ggpht.com/-3BKTe8YFlbA/AAAAAAAAAAI/AAAAAAAAAAA/ad0jqQ4IkGE/s900-c-k-no-mo-rj-c0xffffff/photo.jpg" 
            alt="Logotipo">-->
            <!-- ORIGINAL -->
            <table>
                <tbody class="text-left">
                    <p>
                        <br><span id="tipo"></span>
                        <span v-if="sale.letter=='P'"><br>COMPROBANTE NO VALIDO COMO FACTURA</span>
                        <br>
                        <br><span><br>{{sale.branchoffice.business_name}}
                            {{sale.branchoffice.address}}<br>
                            {{sale.branchoffice.city}}<br>
                            {{sale.branchoffice.state}}</span>
                    </p>
                    <p v-if="(sale.letter!='P')">
                        CUIT: {{sale.branchoffice.cuit}}
                        <br>Ing. Brutos: {{sale.branchoffice.iibb}}
                        <br>{{sale.branchoffice.iva_type}}
                        <br>Inicio de activ. : {{ moment(sale.branchoffice.startdate).format('DD-MM-YYYY')}}
                    </p>
                </tbody>
                <tbody class="text-left">
                    <b v-if="sale.letter!='P'">{{sale.type}}{{(sale.letter!='P') ? ' '+sale.letter : ''}} # {{padLeft(sale.pos,4)}}-{{ padLeft(sale.number, 8) }}</b><br>
                    {{ moment().format('DD-MM-YYYY HH:mm:SS') }}<br>
                </tbody>
                <tbody class="text-left">
                    <br>{{sale.client_name}}
                    <p v-if="(sale.letter!='P')">
                        CUIT:{{sale.client_cuit}}
                        <br>{{sale.client_address}}
                        <br>{{sale.city}}
                    </p>
                </tbody>
                <tbody class="text-left">
                    <template v-for="value in sale.sale_details">
                        <tr :key="value.id">
                            {{ value.quantity }}X {{ value.description.toUpperCase() }}<br>
                            {{ "C/U ".concat(formatoDinamicoMoneda(value.price)," - ",formatoDinamicoMoneda(value.price * value.quantity)).padEnd(32, " ") }}<br>
                        </tr>
                    </template>
                </tbody>
                <tbody class="text-left">
                    <br>SUBTOTAL: {{"".concat(formatoDinamicoMoneda(sale.subtotal)).padEnd(25, " ")}}
                    <p v-if="sale.discount>0">
                    <br>DESCUENTO: {{"".concat(formatoDinamicoMoneda(sale.discount)).padEnd(24, " ")}}
                    </p>
                    <div v-if="sale.letter=='A' || sale.letter=='M'">
                        NETO: {{ formattedNetoGravado(sale.nettaxed) }}<br>
                        <p v-for="(tax, key) in sale.taxes" :key="key">
                            IVA: {{ key }}% {{ formattedTax(key, tax) }}
                        </p>
                    </div>
                    <br><b>TOTAL:{{" ".concat(formatoDinamicoMoneda(sale.total)).padEnd(29, " ")}}</b><br>
                    <p v-if="sale.fundMovement">
                    EFECTIVO:{{"".concat(formatoDinamicoMoneda(sale.fundMovement.amount)).padEnd(25, " ")}}
                    </p>
                    <p v-for="tpcheck in sale.tpchecks" :key="tpcheck.id">
                    CHEQUE:{{"".concat(formatoDinamicoMoneda(tpcheck.amount)).padEnd(27 ," ")}}<br>
                    BANCO: {{tpcheck.name}}<br>
                    NUMERO: {{tpcheck.number}}
                    </p>
                    <p v-if="totalca>0">
                    <br>CTA. CTE.:{{"".concat(formatoDinamicoMoneda(totalca)).padEnd(24, " ")}}
                    </p>
                </tbody>
                <tbody class="text-left">
                    {{(sale.response!=null) ? 'CAE: '+sale.response["CAE"] : ''}}<br>
                    {{(sale.response!=null) ? 'CAE Vto.: '+moment(sale.response["CAEFchVto"]).format('DD-MM-YYYY') : ''}}
                </tbody>
                <tbody v-if="sale.letter=='B'">
                    <p>Régimen de Transparencia Fiscal al Consumidor (Ley 27.743):</p>
                    <p v-for="(tax, key) in sale.taxes" :key="key">
                        IVA: {{ key }}% {{ formattedTax(key, tax) }}
                    </p>
                </tbody>
            </table>
            <br><p class="centrado"><b>LUGAR: {{ sale.id.toString().slice(-2) }}</b></p>
            <p class="centrado">¡GRACIAS POR SU COMPRA!<br></p>
            <p style="font-size: 8px!important;" v-if="sale.response != null">ORIENTACION AL CONSUMIDOR BS AS 0800-222-9042</p>
            <!--QR--> 
            <img v-if="sale.response != null" style="height: 130px;" src='/QR-ticket.png'/>
        </div>


        <!-- DUPLICADO -->
        <div v-if="sale" class="ticket oculto-impresion" id="duplicado">
            <!-- Logo ticket -->
            <!--    
            <img class="imglogo" 
            src="https://yt3.ggpht.com/-3BKTe8YFlbA/AAAAAAAAAAI/AAAAAAAAAAA/ad0jqQ4IkGE/s900-c-k-no-mo-rj-c0xffffff/photo.jpg" 
            alt="Logotipo">-->
            <!-- ORIGINAL -->
            <!-- Logo ticket -->
            <!--    
            <img class="imglogo" 
            src="https://yt3.ggpht.com/-3BKTe8YFlbA/AAAAAAAAAAI/AAAAAAAAAAA/ad0jqQ4IkGE/s900-c-k-no-mo-rj-c0xffffff/photo.jpg" 
            alt="Logotipo">-->
            <!-- ORIGINAL -->
            <table>
                <tbody class="text-left">
                    <p>
                        <br><span id="tipo"></span>
                        <span v-if="sale.letter=='P'"><br>COMPROBANTE NO VALIDO COMO FACTURA</span>
                        <br>
                        <br><span><br>{{sale.branchoffice.business_name}}</span>
                            {{sale.branchoffice.address}}<br>
                            {{sale.branchoffice.city}}<br>
                            {{sale.branchoffice.state}}</p>
                    <p v-if="(sale.letter!='P')">
                        CUIT: {{sale.branchoffice.cuit}}
                        <br>Ing. Brutos: {{sale.branchoffice.iibb}}
                        <br>{{sale.branchoffice.iva_type}}
                        <br>Inicio de activ. : {{ moment(sale.branchoffice.startdate).format('DD-MM-YYYY')}}
                    </p>
                </tbody>
                <tbody class="text-left">
                    <b>{{sale.type}}{{(sale.letter!='P') ? ' '+sale.letter : ''}} # {{padLeft(sale.pos,4)}}-{{ padLeft(sale.number, 8) }}</b><br>
                    {{ moment().format('DD-MM-YYYY HH:mm:SS') }}<br>
                </tbody>
                <tbody class="text-left">
                    <br>{{sale.client_name}}
                    <p v-if="(sale.letter!='P')">
                        CUIT:{{sale.client_cuit}}
                        <br>{{sale.client_address}}
                        <br>{{sale.city}}
                    </p>
                </tbody>
                <tbody class="text-left">
                    <template v-for="value in sale.sale_details">
                        <tr :key="value.id">
                            {{ value.quantity }}X {{ value.description.toUpperCase() }}<br>
                            {{ "C/U ".concat(formatoDinamicoMoneda(value.price)," - ",formatoDinamicoMoneda(value.price * value.quantity)).padEnd(32, " ") }}<br>
                        </tr>
                    </template>
                </tbody>
                <tbody class="text-left">
                    <br>SUBTOTAL: {{"".concat(formatoDinamicoMoneda(sale.subtotal)).padEnd(25, " ")}}
                    <p v-if="sale.discount>0">
                    <br>DESCUENTO: {{"".concat(formatoDinamicoMoneda(sale.discount)).padEnd(24, " ")}}
                    </p>
                    <div v-if="sale.letter=='A' || sale.letter=='M'">
                        NETO: {{ formattedNetoGravado(sale.nettaxed) }}<br>
                        <p v-for="(tax, key) in sale.taxes" :key="key">
                            IVA: {{ key }}% {{ formattedTax(key, tax) }}
                        </p>
                    </div>
                    <br><b>TOTAL:{{" ".concat(formatoDinamicoMoneda(sale.total)).padEnd(29, " ")}}</b><br>
                    <p v-if="sale.fundMovement">
                    EFECTIVO:{{"".concat(formatoDinamicoMoneda(sale.fundMovement.amount)).padEnd(25, " ")}}
                    </p>
                    <p v-for="tpcheck in sale.tpchecks" :key="tpcheck.id">
                    CHEQUE:{{"".concat(formatoDinamicoMoneda(tpcheck.amount)).padEnd(27 ," ")}}<br>
                    BANCO: {{tpcheck.name}}<br>
                    NUMERO: {{tpcheck.number}}
                    </p>
                    <p v-if="totalca>0">
                    <br>CTA. CTE.:{{"".concat(formatoDinamicoMoneda(totalca)).padEnd(24, " ")}}
                    </p>
                </tbody>
                <tbody class="text-left">
                    {{(sale.response!=null) ? 'CAE: '+sale.response["CAE"] : ''}}<br>
                    {{(sale.response!=null) ? 'CAE Vto.: '+moment(sale.response["CAEFchVto"]).format('DD-MM-YYYY') : ''}}
                </tbody>
                <tbody v-if="sale.letter=='B'">
                    <p>Régimen de Transparencia Fiscal al Consumidor (Ley 27.743):</p>
                    <p v-for="(tax, key) in sale.taxes" :key="key">
                        IVA: {{ key }}% {{ formattedTax(key, tax) }}
                    </p>
                </tbody>
            </table>
            <br><p class="centrado"><b>LUGAR: {{ sale.id.toString().slice(-2) }}</b></p>
            <p class="centrado">¡GRACIAS POR SU COMPRA!<br></p>
            <p style="font-size: 8px!important;">ORIENT AL CONSUMIDOR BS AS 0800-222-9042</p>
            <!--QR--> 
            <img v-if="sale.response != null" style="height: 130px;" src='/QR-ticket.png'/>
        </div>
        <b-row class="receipt-footer d-flex justify-content-center oculto-impresion">
                <button class="btn btn-lg btn-orange" @click="imprimir">Imprimir</button>
                <button class="btn btn-lg btn-primary ml-5" onclick="window.location.href = '/ventas/index/nueva-venta'">Volver a punto de venta</button>
        </b-row>
        

    </b-container>
</template>

<script>
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import { sprintf } from 'sprintf-js';


export default {
    props: ['sale_id'],
    mixins: [formatMoneyMixin],
    data: function() {
    return {
            sale: null,
        }
    },
    mounted() {
        this.getSaleDetail(this.sale_id);
    },
    computed: {
        totalca () {
            return this.sale.client_accounts.reduce((partialSum, e) => partialSum + e.amount, 0);
        }
    },
    methods: {
        getSaleDetail(saleId){
            var vm=this;
            axios.get('/api/sales/getSale/' + saleId)
            .then(response => {
                if(response.data.success) {
                    vm.sale = response.data.data;              
                }
            })
            .catch(error => {
                alert(error);
            })
        },
        padLeft(number, length) {
            return number.toString().padStart(length, '0');
        },
        imprimir() {

            var ua = navigator.userAgent.toLowerCase();
            var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");

            if (isAndroid) {
                if (confirm('Desea imprimir DUPLICADO?')) {
                    document.getElementById('tipo').innerHTML = 'ORIGINAL';
                    document.getElementById('duplicado').style.display = "contents";
                    document.getElementById('duplicado').classList.remove("oculto-impresion")
                    window.print();
                }else{
                    document.getElementById('tipo').innerHTML = 'ORIGINAL';
                    window.print();
                }
            }else{
                document.getElementById('tipo').innerHTML = 'ORIGINAL';
                window.print();
            }

            //window.location.href = "/sales/create"
        },
        formattedNetoGravado(value) {
            return sprintf("%19s", "" + this.formatoDinamicoMoneda(value));
        },
        formattedTax(key, value) {
            return sprintf("%25s", "" + this.formatoDinamicoMoneda(value));
        } 
    }
}
</script>

<style>
* {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
}

.centrado {
    text-align: center;
    align-content: center;
}

.ticket {
    color: black;
    width: 50mm;
    max-width: 50mm;
}

.imglogo {
    max-width: inherit;
    width: inherit;
}

#duplicado{
    display: none !important;
}

@media print {
    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
        height: 0;
    }
}
</style>
